<template>
    <v-card>
        <v-card-title class="pa-0">
            <v-toolbar flat dense color="yellow">
                <v-btn icon @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>
                    {{ formTitle }}
                </v-toolbar-title>
                <v-btn class="ml-4"
                       v-if="getGrant('0703')"
                       dark
                       small
                       rounded
                       color="error"
                       @click="copyGuid">
                    <v-icon>mdi-content-copy</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn v-if="this.modificado"
                       dark
                       rounded
                       small
                       color="error"
                       @click="save">
                    Guarda
                </v-btn>
            </v-toolbar>
        </v-card-title>

        <v-card-text v-if="estados != undefined">
            <v-container grid-list-md fluid>
                <v-row>
                    <v-layout wrap>
                        <v-flex xs12 sm6 md2 class="mt-4">
                            <v-chip :text-color="estadoTextColor"
                                    :color="estadoColor"
                                    small
                                    dark>
                                {{ estadoNombre }}
                            </v-chip>
                        </v-flex>

                        <v-flex xs6 sm1>
                            <v-text-field v-model="item.codigo"
                                          type="number"
                                          :disabled="!getGrant('0703')"
                                          label="Código"
                                          mask="###"
                                          @change="change">
                            </v-text-field>
                        </v-flex>

                        <v-flex xs12 sm6 md3>
                            <selectCliente v-model="item.cliente.id"
                                           :disabled="(item.estadoId>ESTADOS.INICIADO && !getGrant('0701')) || !getGrant('0703')"
                                           :texto="item.cliente.nombre"
                                           @change="change"
                                           @texto="item.cliente.nombre = $event">
                            </selectCliente>
                        </v-flex>

                        <v-flex xs12 sm6 md1>
                            <v-text-field v-model="item.nombre"
                                          required
                                          :rules="[v => !!v || 'Obligatorio']"
                                          :disabled="
                                          (item.estadoId>
                                ESTADOS.INICIADO &&
                                !getGrant('0701')) ||
                                !getGrant('0703')
                                "
                                label="Dominio"
                                @change="change"
                                >
                            </v-text-field>
                        </v-flex>

                        <v-flex xs6 sm3 md1>
                            <v-text-field v-model="item.siniestro"
                                          required
                                          :rules="[v => !!v || 'Obligatorio']"
                                          :disabled="(item.estadoId>ESTADOS.INICIADO && !getGrant('0701')) || !getGrant('0703')"
                                          label="Siniestro"
                                          @change="change">
                            </v-text-field>
                        </v-flex>

                        <v-flex xs12 sm6 md2>
                            <fecha v-model="item.fechaSiniestro"
                                   :disabled="!getGrant('0703')"
                                   label="Fecha Siniestro"
                                   @change="change">
                            </fecha>
                        </v-flex>

                        <v-flex xs12 md2>
                            <informacion titulo="Guarda"
                                         :texto="guarda"
                                         text-color="red">
                            </informacion>
                        </v-flex>

                        <v-flex xs12 md3>
                            <infoAuto :item="item"
                                      :disabled="!getGrant('0703')"
                                      @change="changeMarcaModelo">
                            </infoAuto>
                        </v-flex>

                        <v-flex xs6 sm1>
                            <v-text-field v-model="item.anio"
                                          :disabled="!getGrant('0703')"
                                          label="Año"
                                          @change="change">
                            </v-text-field>
                        </v-flex>

                        <v-flex xs6 sm2>
                            <v-text-field v-model="item.color"
                                          :disabled="!getGrant('0703')"
                                          label="Color"
                                          @change="change">
                            </v-text-field>
                        </v-flex>

                        <v-flex xs6 sm2>
                            <v-text-field v-model="item.motor"
                                          :disabled="!getGrant('0703')"
                                          label="Motor"
                                          @change="change">
                            </v-text-field>
                        </v-flex>

                        <v-flex xs6 sm2>
                            <v-text-field v-model="item.chasis"
                                          :disabled="!getGrant('0703')"
                                          label="Chasis"
                                          @change="change">
                            </v-text-field>
                        </v-flex>

                        <v-flex xs12 sm1>
                            <dropboxLink v-model="item.dropboxLink"
                                         :edit="getGrant('0703')"
                                         @change="change" />
                        </v-flex>

                        <v-flex xs12 ms3>
                            <guardaTramite></guardaTramite>
                        </v-flex>
                        <!--<v-flex xs12 ms3>
                            <v-chip @click="print"
                                    class="ma-1"
                                    color="yellow">
                                Imprime
                            </v-chip>
                        </v-flex>-->

                    </v-layout>
                </v-row>
            </v-container>
        </v-card-text>

        <htmlPrint v-if="getGrant('0703')"
                   :item="item"></htmlPrint>

        <v-dialog v-model="cierraDialog" max-width="300px">
            <v-card>
                <v-card-title class="pa-0">
                    <v-toolbar flat dense color="yellow">
                        <v-toolbar-title>
                            Hay cambios pendientes...
                        </v-toolbar-title>
                    </v-toolbar>
                </v-card-title>
                <v-card-text class="pa-10"> </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="cancela">
                        Cancela
                    </v-btn>
                    <v-btn color="error" @click="save">
                        Guarda
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
    import { mapState, mapActions } from 'vuex';

    import fecha from '@/components/Tools/Fecha';

    import htmlPrint from '@/components/Guarda/PrintCodigo';
    import guardaTramite from '@/components/Guarda/GuardaTramite';
    import informacion from '@/components/Tools/Informacion';
    import infoAuto from '@/components/Tools/InfoAuto/InfoAuto';
    import dropboxLink from '@/components/Tools/DropboxLink';

    import selectCliente from '@/components/Selects/selectCliente';

    export default {
        name: 'guardaDialogo',
        data() {
            return {
                cierraDialog: false,
            };
        },

        computed: {
            ...mapState('guarda', ['item', 'id', 'modificado']),
            ...mapState(['TRAMITES', 'ESTADOS']),
            ...mapState('estado', ['all']),
            ...mapState('tramite', { tramiteAll: 'items' }),
            ...mapState('deposito', { depositos: 'items' }),
            ...mapState('account', ['permisos', 'loggedUser']),

            formTitle() {
                return this.index === -1 ? 'Nuevo' : 'Edita';
            },

            estados() {
                if (this.all.items !== undefined) {
                    var itmid = this.item.estadoId;
                    var r = this.all.items.find(function (itm) {
                        if (itmid === itm.id) {
                            return itm;
                        }
                    });
                    return r;
                }
                return '';
            },

            estadoTextColor() {
                if (this.item.estado == undefined) return 'yellow';
                else return this.item.estado.textColor == undefined ? 'black' : this.item.estado.textColor;
            },

            estadoColor() {
                if (this.item.estado == undefined) return 'yellow';
                else return this.item.estado.color;
            },

            estadoNombre() {
                if (this.item.estado == undefined) return '';
                else return this.item.estado.nombre;
            },

            guarda() {
                if (!this.item.depositoId) {
                    return '';
                }
                //if (this.item.deposito == undefined) {
                //    this.item.deposito = this.depositos.find(x => x.id == this.item.depositoId);
                //}

                return this.item.deposito.nombre + ((this.item.puestoId != null) ? (' / ' + this.item.puestoId) : '')
            }
        },

        methods: {
            ...mapActions('guarda', ['closeDialog', 'saveItem', 'setModificado']),
            ...mapActions('estado', ['getAll']),

            copyGuid() {

                /* Copy the text inside the text field */
                let s = window.location.href;

                this.guid = s.substring(0, s.indexOf("/#/")) + '/#/DirectCaso/' + this.item.guid;

                let testingCodeToCopy = document.querySelector('#testing-code')
                testingCodeToCopy.value = this.guid;
                testingCodeToCopy.setAttribute('type', 'text')
                testingCodeToCopy.select()

                try {
                    var successful = document.execCommand('copy');
                    var msg = successful ? 'successful' : 'unsuccessful';
                    if (!successful)
                        alert('Testing code was copied ' + msg);
                } catch (err) {
                    alert('Oops, unable to copy');
                }

                /* unselect the range */
                testingCodeToCopy.setAttribute('type', 'hidden')
                window.getSelection().removeAllRanges()
            },
            change() {
                this.setModificado(true);
            },

            changeMarcaModelo(item) {
                this.item.marca = item.marca;
                this.item.marcaId = item.marca.id;
                this.item.modelo = item.modelo;
                this.item.modeloId = item.modelo.id;
                this.item.modeloValor = item.modeloValor;
                this.item.modeloValorId = item.modeloValor.modeloValorId;
                this.change();
            },

            close() {
                if (this.modificado) this.cierraDialog = true;
                else this.closeDialog();
            },

            save() {
                this.saveItem();
                this.cancela();
            },

            getGrant(grant) {
                return this.loggedUser.grants.indexOf(grant) >= 0;
            },

            cancela() {
                this.cierraDialog = false;
                this.closeDialog();
            }
        },
        mounted() {
            this.getAll();
        },

        components: {
            guardaTramite,
            dropboxLink,
            fecha,
            htmlPrint,
            infoAuto,
            informacion,
            selectCliente,
        }
    };
</script>
