<template>
    <v-card>
        <v-card-title class="pa-0">
            <v-toolbar flat dense color="yellow">
                <v-btn icon @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>
                    {{ formTitle }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn rounded
                       small
                       color="error"
                       @click="save">
                    Guarda
                </v-btn>
            </v-toolbar>

        </v-card-title>

        <v-card-text>
            <v-container grid-list-md>
                <v-row>
                    <v-layout wrap>
                        <v-flex xs12>
                            <modeloValor v-model="mv"
                                         @change="changeModeloValor">
                            </modeloValor>
                        </v-flex>
                        <v-flex xs12 sm6>
                            <selectMarca v-model="mrc.id"
                                         :texto="mrc.nombre"
                                         @change="changeMarca">
                            </selectMarca>
                        </v-flex>

                        <v-flex xs12 sm6>
                            <selectModelo v-model="mdl.id"
                                          :texto="mdl.nombre"
                                          :marcaId="mrc.id"
                                          @change="changeModelo">
                            </selectModelo>
                        </v-flex>
                    </v-layout>
                </v-row>
            </v-container>
        </v-card-text>

    </v-card>
</template>

<script>
    import modeloValor from '@/components/Tools/InfoAuto/ModeloValor'
    import selectMarca from '@/components/Selects/selectMarca';
    import selectModelo from '@/components/Selects/selectModelo';

    export default {
        name: 'infoAutoDialogo',
        data() {
            return {
                modal: false,
                mv: JSON.parse(JSON.stringify(this.item.modeloValor)),
                mrc: JSON.parse(JSON.stringify(this.item.marca)),
                mdl: JSON.parse(JSON.stringify(this.item.modelo))
            };
        },

        props: ['item'],

        watch: {
            item(a, b) {
                this.mrc = JSON.parse(JSON.stringify(this.item.marca));
                this.mdl = JSON.parse(JSON.stringify(this.item.modelo));
                this.mv = JSON.parse(JSON.stringify(this.item.modeloValor));
            },

        },

        methods: {
            close() {
                setTimeout(() => {
                    this.$emit('closeDlg');
                }, 300);
            },

            changeModeloValor(item) {
                this.mv = JSON.parse(JSON.stringify(item));
            },

            changeModelo(item) {
                this.mdl = JSON.parse(JSON.stringify(item));
            },

            changeMarca(item) {
                this.mrc = JSON.parse(JSON.stringify(item));
            },

            save() {
                this.$emit("change", { marca: this.mrc, modelo: this.mdl, modeloValor: this.mv });
                this.close();
            }
        },

        computed: {
            formTitle() {
                return this.mv.marcaId != null && this.mv.modId != '' ? this.mv.marca + ' ' + this.mv.modelo + ' ' + this.mv.tipo : this.mrc.nombre + ' ' + this.mdl.nombre;
            },

        },
        components: {
            modeloValor,
            selectMarca,
            selectModelo,
        }
    };
</script>
