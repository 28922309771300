<template>
    <div>
        <guardaEstado :estadoId="item.estadoId" v-if="getGrant('0703')" />

        <guardaSelTramite v-if="getGrant('0700')" />
        <v-data-table :headers="headers"
                      dense
                      :items="item.guardaTramite"
                      disable-pagination
                      hide-default-footer
                      class="elevation-2 mt-2"
                      @click:row="editTramite">
            <template v-slot:item.nombre="{ item }">
                {{ nombreTramite(item.tramiteId) }}
            </template>

            <template v-slot:item.completado="{ item }">
                <v-icon :color="item.completado ? 'green' : 'red'">
                    {{ completado(item.completado) }}
                </v-icon>
            </template>

            <template v-slot:item.icono="{ item }">
                <v-icon>
                    {{ icono(item.tramiteId) }}
                </v-icon>
            </template>

            <template v-slot:item.fInicio="{ item }">
                {{ fechaFrmt(item.fInicio) }}
            </template>

            <template v-slot:item.fCierre="{ item }">
                {{ fechaCierreFrmt(item.fCierre, item.fInicio) }}
            </template>

            <template v-slot:item.applicationUser.abrv="{ item }"
                      color="lightGray">
                {{ user(item) }}
            </template>

            <template v-slot:item.depositoPuesto="{ item }"
                      color="lightGray">
                {{ depositoPuestoItm(item) }}
            </template>
        </v-data-table>

        <template>
            <v-dialog v-model="propDialog" max-width="800px" persistent>
                <guardaTramiteDialogo />
            </v-dialog>
        </template>
    </div>
</template>
<script>
    import { mapState, mapActions } from 'vuex';
    import guardaTramiteDialogo from '@/components/Guarda/GuardaTramiteDialogo';
    import guardaEstado from '@/components/Guarda/GuardaEstado';
    import guardaSelTramite from '@/components/Guarda/GuardaSelTramite';
    export default {
        name: 'guardaTramite',
        data() {
            return {
                tramiteId: -1,
                ipp: 25,
                headers: [
                    //{ text: 'Id', align: 'left', sortable: true, value: 'id' },
                    //{ text: 'Orden', value: 'orden', sortable: false },
                    { text: 'Tmt.', value: 'icono', sortable: false },
                    { text: 'Nombre', value: 'nombre', sortable: false },
                    { text: 'Iniciado', value: 'fInicio', sortable: false },
                    { text: 'Finalizado', value: 'fCierre', sortable: false },
                    { text: 'Guarda', value: 'depositoPuesto', sortable: false },
                    { text: 'Observaciones', value: 'descripcion', sortable: false },
                    { text: 'Completado', value: 'completado', sortable: false },
                    { text: 'Usr', value: 'applicationUser.abrv', sortable: false }
                ]
            };
        },
        computed: {
            ...mapState('guarda', ['item', 'dialogTramite', 'index', 'tramite', 'nuevoTramite']),
            ...mapState('tramite', { tramiteAll: 'items' }),
            ...mapState('estado', { estadoAll: 'all' }),
            ...mapState('deposito', { depositos: 'items' }),
            ...mapState('account', ['permisos', 'loggedUser']),
            propDialog: {
                get() {
                    return this.dialogTramite;
                },
                set(value) {
                    this.setDialogTramite(value);
                }
            },
            formTitle() {
                return this.index === -1 ? 'Nuevo guarda' : 'Edita guarda';
            },
            todoCompletado() {
                var completado = true;
                this.item.guardaTramite.find(function (itm) {
                    if (itm.completado == false) {
                        completado = false;
                    }
                });
                return completado;
            }
        },

        methods: {
            ...mapActions('guarda', ['setDialogTramite', 'editTramite']),
            completado(completado) {
                if (completado) return 'mdi-checkbox-marked-circle';
                else return 'mdi-close-circle';
            },

            color(completado) {
                if (completado) return 'green';
                else return 'red';
            },

            nombreTramite(tramiteId) {
                //if (this.tramiteAll.loading) return '';
                if (this.tramiteAll !== undefined) {
                    var r = this.tramiteAll.find(function (itm) {
                        if (tramiteId === itm.id) {
                            return itm;
                        }
                    });
                    if (r == undefined) {
                        return '==== Tramite no definido ====';
                    }
                    return r.nombre;
                }
                return '=====';
            },

            icono(tramiteId) {
                //if (this.tramiteAll.loading) return '';
                if (this.tramiteAll !== undefined) {
                    var r = this.tramiteAll.find(function (itm) {
                        if (tramiteId === itm.id) {
                            return itm;
                        }
                    });
                    if (r == undefined) {
                        return '==== Tramite no definido ====';
                    }
                    return r.icono;
                }
                return '=====';
            },

            fechaFrmt(val) {
                if (val === null || val === undefined) return null;
                var str =
                    val.substring(8, 10) +
                    '/' +
                    val.substring(5, 7) +
                    '/' +
                    val.substring(2, 4);
                return str;
                // format date, apply validations, etc. Example below.
                // return this.fromDateVal ? this.formatDate(this.fromDateVal) : "";
            },

            fechaCierreFrmt(val, fInicio) {
                if (val === null || val === undefined) val = fInicio;
                return this.fechaFrmt(val);
            },

            getGrant(grant) {
                return this.loggedUser.grants.indexOf(grant) >= 0;
            },

            depositoPuestoItm(itm) {
                if (itm.depositoId == null || itm.depositoId == 0) {
                    return '';
                }
                else {
                    if (itm.deposito == undefined) {
                        itm.deposito = this.depositos.find(x => x.id == itm.depositoId);
                    }

                    return itm.deposito.nombre + ((itm.puestoId != null) ? (' / ' + itm.puestoId) : '');
                }
            },

            user(tramite) {
                if (tramite.applicationUser !== null)
                    return tramite.applicationUser.abrv;
                return null;
            }
        },
        components: {
            guardaEstado,
            guardaSelTramite,
            guardaTramiteDialogo
        }
    };
</script>

<style>
    .td {
        background-color: red;
    }
</style>
