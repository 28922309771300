<template>
    <div class="pt-2 text-left">
        <v-btn
            v-if="!showEditLink"
            class="mx-1"
            fab
            dark
            x-small
            :color="dropboxColor"
            @click="dropboxClick"
        >
            <v-icon dark>mdi-dropbox</v-icon>
        </v-btn>

        <v-btn
            v-if="link && !showEditLink && edit"
            class="mx-0"
            fab
            dark
            x-small
            @click="showEditLink = true"
        >
            <v-icon dark>mdi-pencil</v-icon>
        </v-btn>

        <v-text-field
            v-if="showEditLink"
            class="pa-0 ma-0"
            v-model="propLink"
            prepend-inner-icon="mdi-checkbox-marked-circle-outline"
            @click:prepend-inner="clickIcon"
        >
        </v-text-field>
    </div>
</template>
<script>
export default {
    name: 'DropboxLink',
    data() {
        return {
            showEditLink: false
        };
    },
    props: ['value', 'edit'],
    computed: {
        propLink: {
            get() {
                return this.value;
            },
            set(val) {
                this.value = val;
            }
        },

        dropboxColor() {
            return this.link ? 'primary' : 'disabled';
        },

        link() {
            return !this.propLink == '';
        }
    },

    methods: {
        clickIcon() {
            this.showEditLink = false;
            this.$emit('input', this.propLink);
            this.$emit('change');
        },

        dropboxClick() {
            if (!this.link) {
                if (this.edit) this.showEditLink = true;
            } else window.open(this.propLink);
        }
    }
};
</script>
