<template>
    <v-card>
        <v-card-title class="pa-0">
            <v-toolbar flat dense color="yellow">
                <v-btn icon @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>
                    Depósito / posición
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn rounded
                       small
                       color="error"
                       @click="save">
                    Guarda
                </v-btn>
            </v-toolbar>

        </v-card-title>

        <v-card-text>
            <v-container grid-list-md>
                <v-row>
                    <v-layout wrap>
                        <v-flex xs12 sm6>
                            <selectDeposito v-model="depositoPuesto.deposito.id"
                                            :texto="depositoPuesto.deposito.nombre"
                                            @change="change">
                            </selectDeposito>
                        </v-flex>

                        <!--  'Puesto' -->
                        <v-flex xs6 sm4>
                            <v-text-field v-model="depositoPuesto.puestoId"
                                          type="number"
                                          label="Puesto">
                            </v-text-field>
                        </v-flex>
                    </v-layout>
                </v-row>
            </v-container>
        </v-card-text>

    </v-card>
</template>

<script>
    import selectDeposito from '@/components/Selects/selectDeposito';

    export default {
        name: 'depositoPuestoDialogo',
        data() {
            return {
                modal: false,
                depositoPuesto: { deposito: { nombre: '' }, puestoId: 0 }
            };
        },

        props: ['item'],

        methods: {
            close() {
                setTimeout(() => {
                    this.$emit('closeDlg');
                }, 300);
            },

            change(item) {
                this.depositoPuesto.deposito = JSON.parse(JSON.stringify(item));
            },

            save() {
                this.depositoPuesto.depositoId = this.depositoPuesto.deposito.id;
                this.$emit('change', this.depositoPuesto);
                this.close();
            }
        },

        mounted() {
            this.depositoPuesto = JSON.parse(JSON.stringify(this.item));
        },

        computed: {
        },

        components: {
            selectDeposito,
        }
    };
</script>
