<template>
    <div>
        <informacion class="mx-auto mt-2"
                     titulo="Guarda"
                     :texto="text"
                     @click="dialog = true">
        </informacion>
        <v-dialog v-model="dialog"
                  max-width="650px"
                  max-heigh="500px"
                  persistent>
            <depositoPuestoDialogo :item="item"
                                   @closeDlg="dialog = false"
                                   @change="change($event)" />
        </v-dialog>
    </div>
</template>
<script>
    import depositoPuestoDialogo from '@/components/Tools/DepositoPuesto/DepositoPuestoDialogo';
    import informacion from '@/components/Tools/Informacion';

    export default {
        name: 'depositoPuesto',

        // item: deposito, puestoId
        props: ['item', 'disabled'],

        data: () => ({
            dialog: false,
            isLoading: false,
            select: null,
            search: null,
            depositoPuesto: {}
        }),

        methods: {
            change(item) {
                this.$emit('change', item);
            },

        },

        computed: {
            //...mapState('titular', ['all', 'defaultTitular']),

            propValue: {
                get() {
                    return {
                        depositoPuesto: { deposito: this.value.deposito, puestoId: this.value.puestoId }
                    };
                },
                set(value) {
                    this.$emit('input', depositoPuesto);
                }
            },

            text() {
                if (this.item.depositoId === null)
                    return '---';

                return this.item.deposito.nombre + ((this.item.puestoId != null) ? (' / ' + this.item.puestoId) : '');
            },

            etiqueta() {
                return (
                    this.titulo +
                    (this.propValue == null || this.propValue.id == 0 || this.propValue.id == null
                        ? ''
                        : ' ' + this.propValue.id)
                );
            }
        },

        watch: {
            search(val) {
                if (this.isLoading) return;

                val && val !== this.propValue.value && this.autocompleta(val);

                if (val !== this.propValue.value) this.propValue = { id: null, value: null };

                this.isLoading = false;
            },

            value(val) {
                if (this.value.id == null || this.value.id == 0) {
                    this.select = { id: null, value: null };
                } else {
                    this.setItems([{ id: this.value.id, value: this.value.fullName }]);
                }
            }
        },

        components: {
            depositoPuestoDialogo,
            informacion,
        }
    };
</script>
