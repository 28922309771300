<template>
    <v-layout row wrap class="mr-1">
        <v-card class="mx-auto mt-2">

            <v-card-text @click="click"
                         class="pa-2">
                {{marcaModelo}}
            </v-card-text>

        </v-card>
        <v-dialog v-model="dialog"
                  max-width="650px"
                  max-heigh="500px"
                  persistent>
            <infoAutoDialogo :item="item"
                             @closeDlg="dialog = false"
                             @change="change($event)" />
        </v-dialog>
    </v-layout>
</template>
<script>

    import infoAutoDialogo from '@/components/Tools/InfoAuto/InfoAutoDialogo';

    export default {
        name: 'infoAuto',
        props: ['value', 'disabled', 'item'],
        data: () => ({
            dialog: false,
            isLoading: false,
            select: null,
        }),

        methods: {
            change(item) {
                this.$emit('change', item);
            },

            click() {
                if (!this.disabled) {
                    this.dialog = true;
                }
            }

        },

        computed: {

            propValue: {
                get() {
                    return { id: this.value.id, value: this.value.fullName };
                },
                set(value) {
                    this.$emit('input', { id: value.id, fullName: value.value });
                }
            },

            marcaModelo() {
                if (this.item.modeloValor != null &&
                    this.item.modeloValor != undefined &&
                    this.item.modeloValor.modeloValorId != '' &&
                    this.item.modeloValor.modeloValorId != null &&
                    this.item.modeloValor.modeloValorId != undefined
                ) {
                    return this.item.modeloValor.marca + ' ' + this.item.modeloValor.modelo + ' ' + this.item.modeloValor.tipo;
                } else {
                    if (this.item.marca.nombre != '' && this.item.modelo.nombre != '') {
                        return this.item.marca.nombre + ' ' + this.item.modelo.nombre;
                    } else {
                        return 'Vehiculo';
                    }
                }
            },

        },

        watch: {
            value(val) {
                if (this.value.id == null || this.value.id == 0) {
                    this.select = { id: null, value: null };
                } else {
                    this.setItems([{ id: this.value.id, value: this.value.fullName }]);
                }
            }
        },

        components: {
            infoAutoDialogo
        }
    };
</script>
