<template>
    <guardaCrud></guardaCrud>
</template>

<script>
import guardaCrud from '@/components/Guarda/GuardaCrud';

export default {
    name: 'guarda',
    data() {
        return {};
    },
    components: {
        guardaCrud
    }
};
</script>
