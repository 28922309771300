<template>
    <v-data-table :headers="headers"
                  dense
                  :loading="progress"
                  :items="items"
                  sort-by="id"
                  sort-desc
                  :search="search"
                  :items-per-page="25"
                  class="elevation-1"
                  @click:row="editItem">
        <template v-slot:top>
            <v-toolbar flat color="yellow">
                <v-toolbar-title>Guarda</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>

                <v-text-field v-model="search"
                              append-icon="mdi-magnify"
                              label="Buscar"
                              single-line
                              hide-details></v-text-field>
                <v-spacer></v-spacer>

                <v-dialog v-model="propDialog"
                          hide-overlay
                          fullscreen
                          transition="dialog-bottom-transition"
                          scrollable
                          persistent>

                    <template v-slot:activator>
                        <v-btn class="mx-2"
                               fab
                               dark
                               x-small
                               color="warning"
                               v-on:click="editItem(null)">
                            <v-icon dark>mdi-plus</v-icon>
                        </v-btn>
                    </template>
                    <guardaDialogo></guardaDialogo>
                </v-dialog>
            </v-toolbar>
        </template>

        <template v-slot:item.estado="{ item }">
            <v-chip x-small
                    :text-color="item.estado.textColor"
                    :color="item.estado.color"
                    dark><strong>{{ item.estado.nombre }}</strong></v-chip>
        </template>

        <template v-slot:item.cliente="{ item }">
            {{ item.cliente }}
        </template>

        <template v-slot:item.fInicio="{ item }">
            <!--{{item.fInicio}}-->
            {{ fechaFrmt(item.fInicio) }}
        </template>

        <!--<template v-slot:item.codigo="{ item }">
        {{ codigo(item) }}
    </template>-->

        <template v-slot:item.alerta="{ item }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">

                    <v-icon v-if="getGrant('0703') && item.alerta"
                            pa-0
                            :color="alertaColor(item)"
                            v-bind="attrs"
                            v-on="on">
                        {{alertaIcono(item)}}
                    </v-icon>
                </template>
                <span>{{tooltip(item) + ' ' +item.dAlerta}}</span>
            </v-tooltip>
        </template>

        <template v-slot:item.dropboxLink="{ item }">
            <v-icon v-if="item.dropboxLink"
                    small
                    @click.stop="dropboxClick(item)">
                mdi-dropbox
            </v-icon>
        </template>

        <template v-slot:item.action="{ item }">
            <v-icon v-if="getGrant('0702')"
                    small @click="deleteItem(item)">
                mdi-delete
            </v-icon>
        </template>
        <template v-slot:no-data>
            <v-btn color="yellow" @click="initialize">Reset</v-btn>
        </template>
    </v-data-table>
</template>

<script>
    import { mapState, mapActions } from 'vuex';

    import guardaDialogo from '@/components/Guarda/GuardaDialogo';

    export default {
        name: 'guarda',
        data() {
            return {
                search: '',
                formTitle: '',
                headers: [
                    { text: '', value: 'alerta', show: true },
                    { text: 'Código', value: 'codigo', show: true },
                    { text: 'Estado', value: 'estado', show: true },
                    { text: 'F.Inicio', value: 'fInicio', show: true },
                    //{ text: 'Id', align: 'left', sortable: true, value: 'id' },
                    { text: 'Compañia', value: 'cliente', show: true },
                    { text: 'Siniestro', value: 'siniestro', show: true },
                    { text: 'Dominio', value: 'nombre', show: true },
                    { text: 'Marca y Modelo', value: 'marcaModelo', show: true },
                    { text: 'Deposito/Puesto', value: 'depositoPuesto', show: true },
                    { text: 'Color', value: 'color', show: true },
                    { text: 'Dbx', value: 'dropboxLink', show: true },
                    { text: 'Opciones', value: 'action', sortable: false }
                ]
            };
        },

        computed: {
            ...mapState('guarda', ['dialog', 'items', 'item', 'borraDialog', 'progress']),
            ...mapState(['ESTADOS']),
            ...mapState('tramite', { tramiteAll: 'items' }),
            ...mapState('account', ['permisos', 'loggedUser']),
            ...mapState('cliente', { clientes: 'all' }),

            propDialog: {
                get() {
                    return this.dialog;
                },
                set(value) {
                    this.setDialog(value);
                }
            },

        },

        async created() {
            var self = this;
            await this.initialize();
            self.getCompact();

        },

        methods: {
            ...mapActions('guarda', ['initialize', 'editItem', 'setDialog', 'borraItem', 'deleteItem', 'closeBorraDialog', 'setDialog','getCompact']),

            getGrant(grant) {
                return this.loggedUser.grants.indexOf(grant) >= 0;
            },

            alertaColor(item) {
                if (item.alerta == 1)
                    return "warning";

                let fin = new Date(item.fAlerta);
                let hoy = new Date();
                return hoy > fin ? "primary" : "blue lighten-5";
            },

            alertaIcono(item) {
                return (item.alerta == 1) ? "mdi-phone-outgoing" : "mdi-calendar-clock";
            },

            dropboxClick(itm) {
                window.open(itm.dropboxLink);
            },

            fechaFrmt(val) {
                if (val === null || val === undefined) return null;
                var str =
                    val.substring(8, 10) +
                    '/' +
                    val.substring(5, 7) +
                    '/' +
                    val.substring(2, 4);
                return str;
                // format date, apply validations, etc. Example below.
                // return this.fromDateVal ? this.formatDate(this.fromDateVal) : "";
            },

            tooltip(item) {
                if (item.alerta == 1)
                    return "Primer contacto";

                let dt = new Date(item.fAlerta);
                return dt.getDate() + '/' + (dt.getMonth() + 1) + '/' + dt.getFullYear();
            },

        },
        components: {
            guardaDialogo
        }
    };
</script>
