<template>
    <v-layout row wrap class="mr-1">
        <v-flex xs6 sm2>
            <v-text-field v-model="value.marcaId"
                          label="Marca">
            </v-text-field>
        </v-flex>
        <v-flex xs6 sm2>
            <v-text-field v-model="value.tipoId"
                          label="Tipo Vcl">
            </v-text-field>
        </v-flex>
        <v-flex xs6 sm2>
            <v-text-field v-model="value.modId"
                          label="Modelo">
            </v-text-field>
        </v-flex>
        <v-flex xs1>
            <v-btn class="mt-4"
                   color="yellow"
                   @click="valida">
                <v-icon>mdi-magnify</v-icon>
            </v-btn>
        </v-flex>

        <v-flex xs12>
        </v-flex>
        <v-flex xs12>
            <span>
                {{descripcion}}
            </span>
        </v-flex>
    </v-layout>
</template>
<script>
    import { mapState, mapActions } from 'vuex';

    export default {
        name: 'modeloValor',
        props: ['value', 'titulo', 'disabled'],
        data: () => ({
            dialog: false,
            isLoading: false,
            select: null,
            search: null
        }),

        computed: {
            ...mapState('modelovalor', ['item']),

            descripcion() {
                if (this.value.modeloValorId != "" && this.value.modeloValorId != null) {
                    // MTM (Marca tipo modelo)
                    if (this.value.fabId == '' || this.value.fabId == null) {
                        return (this.value.modeloValorTipo ? '' : this.value.modeloValorId) +
                            (this.value.marca == null || this.value.marca == '' ? '' : this.value.marca + ' ') +
                            (this.value.tipo == null || this.value.tipo == '' ? '' : this.value.tipo + ' ') +
                            (this.value.modelo == null || this.value.modelo == '' ? '' : this.value.modelo + ' ') +
                            this.value.modeloValorTipo == null ? '' : '(' + this.value.modeloValorTipo.tipVehiculoId + ' ' + this.value.modeloValorTipo.tipVehiculo.nombre + ')';
                    } else {
                        // FMM (Fabricante Marca Modelo)
                        return (this.value.modeloValorId == null || this.value.modeloValorId == '' ? '' : this.value.modeloValorId) +
                            (this.value.fabId == null || this.value.fabId == '' ? '' : this.value.fabId + ' ') +
                            (this.value.marca == null || this.value.marca == '' ? '' : this.value.marca + ' ') +
                            (this.value.modelo == null || this.value.modelo == '' ? '' : this.value.modelo + ' ') +
                            '(' + this.value.modeloValorTipo.tipVehiculoId + ' ' + this.value.modeloValorTipo.tipVehiculo.nombre + ')';
                    }
                }
            }
        },

        methods: {
            ...mapActions('modelovalor', ['getById', 'getByMarcaModeloTipo']),

            async valida() {
                let self = this;
                await this.getByMarcaModeloTipo({
                    marcaId: self.value.marcaId,
                    modeloId: self.value.modId,
                    tipoId: self.value.tipoId
                });

                let reg = self.item

                if (reg != undefined && reg != "") {
                    reg.modeloValorTipo = {
                        tipVehiculoId: self.item.tipVehiculoId, tipVehiculo: { id: self.item.tipVehiculoId, nombre: self.item.tipVehiculo }
                    }
                    let r = JSON.parse(JSON.stringify(reg));
                    if (r != null && r != undefined) {
                        self.$emit('change', r);
                    }
                }
                else {
                    let r = {
                        modeloValorId: '',
                        in: null,
                        t: null,
                        fabId: null,
                        marcaId: null,
                        tipoId: null,
                        modId: null,
                        marca: null,
                        tipo: null,
                        modelo: null,
                        tipVehiculoId: null,
                        tipVehiculo: null,
                        modeloValorAnioDTO: []};
                    if (r != null && r != undefined) {
                        self.$emit('change', r);
                    }
                }

            },

            async changeCodigo(item) {
                let cod = this.value.modeloValorId;
                await this.getById(cod);

                let newvalue = this.item;
                if (newvalue != null && newvalue != undefined) {
                    this.$emit('input', newvalue);
                    this.$emit('change', newvalue);
                } else {
                    let r = JSON.parse(JSON.stringify(this.value));
                    if (r != null && r != undefined) {
                        r.modeloValorId = null;
                        r.in = null;
                        r.t = null;
                        r.fabId = null;
                        r.marcaId = null;
                        r.marca = null;
                        r.modId = null;
                        r.modelo = null;
                        r.tipoId = null;
                        r.tipo = null;
                        r.tipVehiculoId = null;
                        r.tipVehiculo = null;
                        this.$emit('change', r);
                    }
                }
            },

        },

        value(val) {
            if (this.value.id == null || this.value.id == 0) {
                this.select = { id: null, value: null };
            } else {
                this.setItems([{ id: this.value.id, value: this.value.fullName }]);
            }
        }
    };
</script>
