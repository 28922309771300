var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"dense":"","loading":_vm.progress,"items":_vm.items,"sort-by":"id","sort-desc":"","search":_vm.search,"items-per-page":25},on:{"click:row":_vm.editItem},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"yellow"}},[_c('v-toolbar-title',[_vm._v("Guarda")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-dialog',{attrs:{"hide-overlay":"","fullscreen":"","transition":"dialog-bottom-transition","scrollable":"","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","x-small":"","color":"warning"},on:{"click":function($event){return _vm.editItem(null)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-plus")])],1)]},proxy:true}]),model:{value:(_vm.propDialog),callback:function ($$v) {_vm.propDialog=$$v},expression:"propDialog"}},[_c('guardaDialogo')],1)],1)]},proxy:true},{key:"item.estado",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"x-small":"","text-color":item.estado.textColor,"color":item.estado.color,"dark":""}},[_c('strong',[_vm._v(_vm._s(item.estado.nombre))])])]}},{key:"item.cliente",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.cliente)+" ")]}},{key:"item.fInicio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.fechaFrmt(item.fInicio))+" ")]}},{key:"item.alerta",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.getGrant('0703') && item.alerta)?_c('v-icon',_vm._g(_vm._b({attrs:{"pa-0":"","color":_vm.alertaColor(item)}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.alertaIcono(item))+" ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.tooltip(item) + ' ' +item.dAlerta))])])]}},{key:"item.dropboxLink",fn:function(ref){
var item = ref.item;
return [(item.dropboxLink)?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.dropboxClick(item)}}},[_vm._v(" mdi-dropbox ")]):_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(_vm.getGrant('0702'))?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")]):_vm._e()]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"yellow"},on:{"click":_vm.initialize}},[_vm._v("Reset")])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }