<template>
    <v-card class="pb-1"
            @click="$emit('click',0)">
        <ul class="pa-0">
            <li>
                <label class="pr-2" xs12>{{ titulo }}</label>
            </li>
            <li>
                <v-chip v-if="tx!=''"
                        xs12
                        small
                        :text-color="'text-color'"
                        :color="color"
                        :dark="dark">
                    {{ tx }}
                </v-chip>
                <p v-else class="pb-2"></p>
            </li>
        </ul>
    </v-card>
</template>

<script>
    export default {
        name: 'informacion',
        props: {
            titulo: {
                type: String,
                default: ''
            },
            texto: {
                type: String,
                default: ''
            },
            'text-color': {},
            'color': {},
            'dark': {}
        },

        data() {
            return {};
        },
        computed: {
            tx() {
                if (this.texto)
                    return this.texto;
                else
                    return '';
            }
        },

    };
</script>
<style>
    ul {
        list-style-type: none;
    }
</style>
