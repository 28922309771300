<template>
    <v-card>
        <v-card-title class="pa-0">
            <v-toolbar flat dense color="yellow">
                <v-btn icon @click="closeDialogTramite">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>
                    {{ titulo }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn text @click="save">Guarda</v-btn>
            </v-toolbar>
        </v-card-title>

        <v-card-text>
            <v-container grid-list-md>
                <v-row>
                    <v-layout wrap>
                        <!-- 'Inicio' -->
                        <v-flex xs6 v-if="viewCampo(1)">
                            <fecha v-model="tramite.fInicio"
                                   :label="lblCampo(1)"></fecha>
                        </v-flex>
                        <!-- 'Cierre' -->
                        <v-flex xs6 v-if="viewCampo(2)">
                            <fecha v-model="tramite.fCierre"
                                   :label="lblCampo(2)"></fecha>
                        </v-flex>
                        <!-- '# Comprobante'  'Fecha'   'Importe' -->
                        <v-flex v-if="viewCampo(8) || viewCampo(9) || viewCampo(10)">
                            <v-container grid-list-md>
                                <v-row>
                                    <v-layout wrap>
                                        <!-- '# Comprobante' -->
                                        <v-flex xs6 sm4 v-if="viewCampo(8)">
                                            <v-text-field v-model="tramite.numeroComprobante"
                                                          :label="lblCampo(8)"></v-text-field>
                                        </v-flex>
                                        <!-- 'Fecha' -->
                                        <v-flex xs6 sm4 v-if="viewCampo(9)">
                                            <fecha v-model="tramite.fechaComprobante"
                                                   :label="lblCampo(9)"></fecha>
                                        </v-flex>
                                        <!--  'Importe' -->
                                        <v-flex xs6 sm4 v-if="viewCampo(10)">
                                            <v-text-field v-model="tramite.importeComprobante"
                                                          type="number"
                                                          :label="lblCampo(10)"></v-text-field>
                                        </v-flex>
                                    </v-layout>
                                </v-row>
                            </v-container>
                        </v-flex>

                        <!--  Deposito  -->
                        <v-flex v-if="viewCampo(20)">
                            <!--  'Deposito/puesto' -->
                            <v-flex xs6 sm12 v-if="viewCampo(20)">
                                <depositoPuesto :item="depositoPuestoItem"
                                                @change="changeDepositoPuesto($event)">
                                </depositoPuesto>
                            </v-flex>
                        </v-flex>

                        <!-- 'Detalle de trámite' -->
                        <v-flex xs10 v-if="viewCampo(12)">
                            <v-textarea v-model="tramite.descripcion"
                                        auto-grow
                                        rows="2"
                                        dense
                                        :label="lblCampo(12)"></v-textarea>
                        </v-flex>

                        <!-- 'Completado' -->
                        <v-flex xs2 v-if="viewCampo(13)">
                            <v-checkbox :disabled="disabledCompletado"
                                        v-model="tramite.completado"
                                        class="mx-2"
                                        :label="lblCampo(13)"
                                        @click="completa"></v-checkbox>
                        </v-flex>

                    </v-layout>
                </v-row>
            </v-container>
        </v-card-text>
    </v-card>
</template>

<script>
    import { mapState, mapActions } from 'vuex';
    import depositoPuesto from '@/components/Tools/DepositoPuesto/DepositoPuesto';
    import fecha from '@/components/Tools/Fecha';

    export default {
        name: 'guardaTramiteDialogo',

        data() {
            return {
                completado: false,
                editedIndex: -1
            };
        },
        computed: {
            ...mapState(['TRAMITES']),
            ...mapState('campotramite', { campotramiteAll: 'items' }),
            ...mapState('modelovalor', { modeloValorAll: 'items' }),
            ...mapState('modelovalortipo', { modeloValorTipoAll: 'items' }),
            ...mapState('guarda', ['item', 'modificado', 'nuevoTramite', 'tramite']),
            ...mapState('tramite', ['items']),
            ...mapState('account', ['loggedUser']),

            depositoPuestoItem() {
                return { deposito: this.tramite.deposito, depositoId: this.tramite.depositoId, puestoId: this.tramite.puestoId, };
            },

            TipoVehiculo() {
                if (!this.item.modeloValorId) {
                    return this.item.modelo.tipVehiculoId;
                }
                else {
                    let cod = this.modeloValorAll.find(x => x.modeloValorId == this.item.modeloValorId);
                    let tipo = this.modeloValorTipoAll.find(x => x.tipo == cod.tipoId);
                    return tipo.tipVehiculoId;
                }
            },

            costoTraslado() {
                return this.calculaCostoTraslado();
            },

            //   this.tramite.tramiteId == this.TRAMITES.ANULAR
            //    this.tramite.tramiteId == this.TRAMITES.REACTIVAR ||
            //|| this.tramite.tramiteId == this.TRAMITES.OBSERVACION
            //|| this.tramite.tramiteId == this.TRAMITES.OBSERVACION_INTERNA
            //|| this.tramite.tramiteId == this.TRAMITES.OTROS_GASTOS
            //|| this.tramite.tramiteId == this.TRAMITES.INICIAR
            //|| this.tramite.tramiteId == this.TRAMITES.PREINFORME
            //|| this.tramite.tramiteId == this.TRAMITES.ORDEN_DE_TRASLADO
            //|| this.tramite.tramiteId == this.TRAMITES.PEDIDO_DE_TRASLADO
            //|| this.tramite.tramiteId == this.TRAMITES.PEDIDO_04D
            //|| this.tramite.tramiteId == this.TRAMITES.GESTORIA
            //|| this.tramite.tramiteId == this.TRAMITES.BAJA_A_COMPANIA_MAIL
            //|| this.tramite.tramiteId == this.TRAMITES.DOCUMENTACION_FISICA_A_COMPANIA
            //|| this.tramite.tramiteId == this.TRAMITES.ACREEDOR_PRENDARIO
            //|| this.tramite.tramiteId == this.TRAMITES.FINALIZADO
            //|| this.tramite.tramiteId == this.TRAMITES.VALORES_INFOAUTO
            //|| this.tramite.tramiteId == this.TRAMITES.COBRO_A_DESARMADEROS
            //|| this.tramite.tramiteId == this.TRAMITES.COBRO_GESTORIA
            //|| this.tramite.tramiteId == this.TRAMITES.COBRO_OTROS_GASTOS
            //|| this.tramite.tramiteId == this.TRAMITES.PAGO_A_COMPANÍA
            //|| this.tramite.tramiteId == this.TRAMITES.FC_COMPANIA_A_DESARMADERO
            //|| this.tramite.tramiteId == this.TRAMITES.COMISION_BAJA

            titulo() {
                var trmtId = this.tramite.tramiteId;
                var r = this.items.find(function (itm) {
                    if (trmtId === itm.id) {
                        return itm;
                    }
                });
                if (r == undefined)
                    return '';
                return r.nombre;
            },

            disabledCompletado() {
                var tmt = this.items.find(x => x.id == this.tramite.tramiteId);
                if (tmt == null) return false;
                const self = this;

                var cmp = tmt.tramiteCampo.filter(x => x.obligatorio);

                var field = cmp.map(item => {
                    const field = self.campotramiteAll.find(x =>
                        x.id == item.campoTramiteId
                        && x.campoTramiteName != ''
                    );
                    return field == undefined ? undefined : field.campoTramiteName;
                });
                let ret = true;
                field.forEach(item => {
                    ret &= (item == undefined ? true : this.tramite[item] != null);
                });
                return !ret || this.tramite.completado;
            },

            //disabledCompletado() {
            //    return (
            //        (this.tramite.tramiteId == this.TRAMITES.PEDIR_INFORMES &&
            //            (this.item.estadoInforme.id == null ||
            //                this.item.estadoInforme.id == 0)) ||
            //        this.tramite.completado == true
            //    );
            //},

        },

        methods: {
            ...mapActions('guarda', ['closeDialogTramite', 'pushChild', 'setState', 'setModificado']),
            ...mapActions('alert', ['errorTime', 'error']),

            calculaCostoGestoria() {
                var tmt_gestoria = this.item.guardaTramite.find(
                    x => x.tramiteId == this.TRAMITES.GESTORIA
                );
                if (tmt_gestoria == undefined) {
                    return 0;
                }
                return tmt_gestoria.importeComprobante;
            },

            changeDepositoPuesto(itm) {
                this.tramite.deposito = itm.deposito;
                this.tramite.depositoId = itm.depositoId;
                this.tramite.puestoId = itm.puestoId;
            },

            calculaCostoTraslado() {
                var tmt_traslado = this.item.guardaTramite.find(
                    x => x.tramiteId == this.TRAMITES.PEDIDO_DE_TRASLADO
                );
                if (tmt_traslado == undefined) {
                    return 0;
                }
                return tmt_traslado.importeComprobante;
            },

            viewCampo(campo) {
                var tmt = this.items.find(x => x.id == this.tramite.tramiteId);
                if (tmt == null) return false;
                var cmp = tmt.tramiteCampo.find(x => x.campoTramiteId == campo);
                return cmp != undefined;
            },

            lblCampo(campo) {
                var tmt = this.items.find(x => x.id == this.tramite.tramiteId);
                if (tmt == null) return '';
                var cmp = tmt.tramiteCampo.find(x => x.campoTramiteId == campo);
                return cmp != undefined ? cmp.nombre : '';
            },

            valida(completa) {
                var ok = true;

                if (this.tramite.fInicio == null) {
                    ok = false;
                    this.errorTime('Debe tener fecha de inicio');
                } else {
                    if (
                        completa &&
                        this.tramite.fCierre == null &&
                        (this.tramite.tramiteId == this.TRAMITES.PREINFORME ||
                            this.tramite.tramiteId == this.TRAMITES.PEDIDO_DE_TRASLADO ||
                            this.tramite.tramiteId == this.TRAMITES.PEDIDO_04D ||
                            this.tramite.tramiteId == this.TRAMITES.GESTORIA)
                    ) {
                        ok = false;
                        this.errorTime('Debe tener fecha de cierre');

                    } else {
                        if (
                            completa &&
                            ok &&
                            (this.tramite.tramiteId == this.TRAMITES.PREINFORME ||
                                this.tramite.tramiteId ==
                                this.TRAMITES.PEDIDO_DE_TRASLADO ||
                                this.tramite.tramiteId == this.TRAMITES.PEDIDO_04D ||
                                this.tramite.tramiteId == this.TRAMITES.GESTORIA) &&
                            this.tramite.fInicio.substr(0, 10) > this.tramite.fCierre.substr(0, 10)
                        ) {
                            ok = false;
                            this.error('La fecha de inicio debe ser anterior a la fecha de cierre');
                        }
                    }
                }
                if (ok)
                    this.setModificado(true);

                return ok;
            },

            save() {
                var slf = this;
                let ret = this.valida(false);
                if (ret) {
                    slf.tramite.applicationUserId = slf.loggedUser.id;
                    slf.tramite.applicationUser = null;
                    if (slf.tramite.nuevoTramite) {
                        slf.pushChild(slf.tramite);
                    }
                    this.setState();
                    slf.closeDialogTramite();
                }
            },

            completa() {
                var slf = this;
                //this.tramite.completado = this.valida(true);
                //if (this.tramite.completado) {
                var trmt = 0;

                this.items.find(function (itm, i) {
                    if (slf.tramite.tramiteId === itm.id) {
                        trmt = i;
                    }
                });
                if (this.tramite.tramiteId >= this.TRAMITES.INICIAR) {
                    // observaciones no cambia estado
                    this.item.estadoId = this.items[trmt].estadoFinal.id;
                    this.item.estado.id = this.items[trmt].estadoFinal.id;
                    this.item.estado.nombre =
                        this.items[trmt].estadoInicial.nombre;
                }
                this.tramite.completado = true;
                this.save();
                //}
            },

            fmtDouble(val) {
                if (val == '' || val == null) {
                    return '$ 0.00';
                }
                return '$ ' + Number(val).toLocaleString();
            },
        },

        components: {
            depositoPuesto,
            fecha
        }
    };
</script>
