<template>
    <v-card color="blue-grey lighten-5"
            dark
            dense>
        <v-card-text class="pa-0">
            <v-row class="ma-0">
                <v-menu v-for="(itm, inx) in tramites"
                        :key="inx">
                    <!--<v-chip @click="newTramite(itm.id)"
                            small
                            class="ma-1"
                            :color="'grey'">
                        {{ itm.nombre }}
                    </v-chip>-->
                    <template v-slot:activator>
                        <v-chip-group>
                            <v-chip @click="newTramite(itm.id)"
                                    small
                                    class="ma-1"
                                    :color="'grey'">
                                {{ itm.nombre }}
                            </v-chip>
                        </v-chip-group>
                    </template>

                </v-menu>
            </v-row>
        </v-card-text>
    </v-card>
    <!--<v-bottom-navigation horizontal
                         absolute
                         dark
                         fluid
                         background-color="blue-grey lighten-5"
                         class="mt-3"
                         height="26">
        <v-container fluid class="pa-0">
            <v-row>
                <v-layout>
                    <v-col class="pt-1"
                           v-for="(item, inx) in tramites" :key="inx">
                        <v-chip @click="newTramite(item.id)"
                                small
                                :color="'grey'">
                            {{ item.nombre }}
                        </v-chip>
                    </v-col>
                </v-layout>
            </v-row>
        </v-container>
    </v-bottom-navigation>-->
</template>

<script>
    import { mapState, mapActions } from 'vuex';

    export default {
        name: 'guardaSelTramite',
        data() {
            return {
                alerta: false
            };
        },

        computed: {
            ...mapState('guarda', [
                'item',
                'defaultTramite',
                'tramite',
                'dialogTramite'
            ]),
            ...mapState('tramite', ['items']),

            propDialog: {
                get() {
                    return this.dialogTramite;
                },

                set(value) {
                    this.setDialogTramite(value);
                }
            },

            tramites() {
                if (this.items !== undefined) {
                    var ret = this.items.filter(x => (x.modulo & 16) == 16);
                    return ret;
                }
                return [];
            }
        },

        mounted() {
            this.getAll();
        },

        methods: {
            ...mapActions('guarda', [
                'newTramite',
                'pushChild',
                'setDialogTramite',
                'setAlerta',
                'tramitesCompletados'
            ]),
            ...mapActions('tramite', ['getAll']),

            clkalerta() {
                this.alerta = !this.alerta;
                this.setAlerta({ alertaId: 1, value: this.alerta });
            }
        }
    };
</script>
